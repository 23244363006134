import React from 'react'
import Helmet from 'react-helmet'
import undraw from '../assets/images/undraw_1.svg'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'

const HomeIndex = () => {
  const siteTitle = 'Acervo Digital'
  const siteDescription = 'Acervo Digital para que nuestros estudiantes encontren recursos'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>
              Queremos formarte integralmente como todo
              <br />
              un profesionista competitivo.
            </h2>
          </header>
          <img src={undraw} style={{width:200,height:200}} />

          <p>
            El <strong>TecNM</strong> y su <strong>Campus Coatzacoalcos</strong> como institución de educación superior tecnológica de vanguardia busca apoyarte en todo momento.
            Éste sitio es tuyo, úsalo y vuelve cuando necesites referencias para
            tus proyectos personales o profesionales.
          </p>
          <ul className="actions">
            <li>
              <a href="https://www.tecnm.mx/?vista=MisionVision" target="_blank" className="button">
                Misión y Visión del TECNM
              </a>
            </li>
          </ul>
        </section>

        <section id="two">
          <h2>Comienza a descubrir</h2>

          <Gallery />


        </section>


      </div>
    </Layout>
  )
}

export default HomeIndex
