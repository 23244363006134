import thumb02 from '../../../assets/images/newthumbs/t2.png'
import thumb01 from '../../../assets/images/newthumbs/t1.png'
import thumb03 from '../../../assets/images/newthumbs/t3.png'
import thumb04 from '../../../assets/images/newthumbs/t4.png'
import thumb05 from '../../../assets/images/newthumbs/t5.png'
import thumb06 from '../../../assets/images/newthumbs/t6.png'
import thumb07 from '../../../assets/images/newthumbs/t7.png'
import thumb08 from '../../../assets/images/newthumbs/t8.png'
import thumb09 from '../../../assets/images/newthumbs/t9.png'
import thumb10 from '../../../assets/images/newthumbs/t10.png'


export const DEFAULT_IMAGES = [
    {
        id: '1',
        source: 'https://www.scirp.org/',
        thumbnail: thumb01,
        caption: 'SCIRP',
        description: 'En inglés',
    },
    {
        id: '2',
        source: 'https://www.researchgate.net/',
        thumbnail: thumb02,
        caption: 'Research Gate',
        description: 'En inglés',
    },
    {
        id: '3',
        source: 'https://www.imedpub.com/computer-science-and-engineering-survey/',
        thumbnail: thumb03,
        caption: 'IMEDPUB',
        description: 'En inglés',
    },
    {
        id: '4',
        source: 'https://www.springer.com/la/computer-science/all-journals-in-computer-science',
        thumbnail: thumb04,
        caption: 'SPRINGER',
        description: 'En inglés',
    },
    {
        id: '5',
        source: 'http://www.iiisci.org/Journal/riSCI/',
        thumbnail: thumb05,
        caption: 'IIISCI',
        description: 'En inglés',
    },
    {
        id: '6',
        source: 'https://search.scielo.org/?lang=es',
        thumbnail: thumb06,
        caption: 'SCIELO',
        description: 'En español',
    },
    {
        id: '7',
        source: 'http://revistas.unam.mx/index.php/cys',
        thumbnail: thumb07,
        caption: 'UNAM',
        description: 'En español',
    }
    ,
    {
        id: '8',
        source: 'https://www.riti.es/ojs2018/inicio/index.php/riti/index',
        thumbnail: thumb08,
        caption: 'RITI',
        description: 'En español',
    }
    ,
    {
        id: '9',
        source: 'https://revistas.unab.edu.co/',
        thumbnail: thumb09,
        caption: 'UNAB',
        description: 'En español',
    }
    ,
    {
        id: '10',
        source: 'https://polipapers.upv.es/index.php/RIAI/index',
        thumbnail: thumb10,
        caption: 'POLIPAPERS',
        description: 'En español',
    }
]